<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('bindAccount.default[0]')"
            left-arrow
            @click-left="$router.go(-2)"
    />
    <div class="ScrollBox">
          <van-form @submit="onSubmit">
            <div class="box">
                <van-field :border="false"
                        v-model="BankInfo.bank_name"
                        :label="$t('bindAccount.fields2[3]')"
                        :placeholder="$t('bindAccount.placeholder2[3]')"
                />
                <van-field :border="false"
                        v-model="BankInfo.name"
                        :label="$t('bindAccount.fields[0]')"
                        :placeholder="$t('bindAccount.placeholder[0]')"
                />
                <van-field :border="false"
                        v-model="BankInfo.card_no"
                        :label="$t('bindAccount.fields[2]')"
                        :placeholder="$t('bindAccount.placeholder[2]')"
                />
                <van-field :border="false"
                        v-model="BankInfo.phone"
                        :label="$t('bindAccount.fields[1]')"
                        :placeholder="$t('bindAccount.placeholder[1]')"
                />
            </div>
              <div style="line-height: 45px;text-align: left">
                  <div style="margin-left: 20px">{{$t('bindAccount2[0]')}}</div>
              </div>
            <div style="text-align: center;margin-top: 20px" v-show="canEdit">
              <p class="btn" @click="onSubmit">{{ $t('bindAccount2[1]') }}</p>
            </div>
              <div style="line-height: 45px">
                  <div style="margin-left: 20px">{{$t('busAuth2[4]')}}</div>
              </div>
              <div style="margin: 0px auto;width: 90%;text-align: center;margin-top: 30px">
                  <van-button color="#DAA78A" size="large" style=""
                              to="/serviceCenter"
                  ><span style="color:#FFF">{{$t('bindAccount2[2]')}}</span>
                  </van-button>
              </div>
          </van-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      columns : ['ERC20', 'TRC20', 'OMNI'],
      BankInfo:{},
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      canEdit:true,
      tabsList: [],
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()

    this.BankInfo.coin_type = this.columns[0];
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
        this.BankInfo = data['data'][0];
        this.canEdit = false;
      }
      }
    );
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    onSubmit() {
      this.$Model.AddBankCard(this.BankInfo,data=>{
         var msg = "";
         if (data.code==1){
             msg = this.$t('common4[1]');
             this.$router.push("/");
         }else{
             msg = data.code_dec;
         }
          this.$Dialog.Toast(msg);
      });
    },
    onChangeType(obj,value){
      this.BankInfo.coin_type = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
  .PageBox {
     color: #588686;
     background-color: #0F4F4E;
 }
  .PageBox .box {
      width: 95%;
      padding: 10px 0px 10px 10px;
      font-size: 13px;
      margin-top: 15px;
      border-radius: 10px;
      margin-left: 10px;
      background-color: #337F7B;
  }
  .van-nav-bar {
      background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__title{
      color:#FFFEFF;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
      font-size: 20px;
      color:#FFFEFF;
  }
  .PageBox .van-cell{
      background-color: #337F7B;
  }

  .van-cell>>>.van-cell__title{
      font-size: 16px;
      width: 130px;
      color:#8CBFC0;
  }
  .van-cell >>> .van-field__control {
      color: #FFFEFF !important;
      padding-left: 7px;
      height: 100%;
  }

  .van-cell >>> .van-field__control::-webkit-input-placeholder {
      color: #e7e7e7;
      font-family: Alibaba;
  }

  .van-cell >>> .van-field__control::-moz-placeholder {
      color: #e7e7e7;
      font-family: Alibaba;
  }

  .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
      margin: 0;
      background-color: #f7f8fa;
      border-radius: 8px;
  }

  .van-field{
      font-weight: bold;
  }

  .btn {
      width: 85%;
      padding: 10px 50px;
      border-radius: 5px;
      background-color: #DAA78A;
      color: #FFF;
      font-size: 16px;
      text-align: center;
      margin: 15px 30px 30px;
  }
</style>
